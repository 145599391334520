
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    setup() {

        const theParams = ref({});
        const ccvAmount = ref("");
        const ccvCur = ref("");

        onMounted(()=>{

            let urlParams = new URLSearchParams(location.href);
            console.log("PosNomuPayForm URL Params alldata: "+getParamByName("alldata", location.href));
            theParams.value = JSON.parse(getParamByName("alldata", location.href));
            if ((theParams.value as any).RedirectUrl!=="") {
                window.location.href = (theParams.value as any).RedirectUrl;
            }



            /*
            let urlParams = new URLSearchParams(location.href);
            console.log("PosNomuPayForm URL Params alldata: "+getParamByName("alldata", location.href));
            theParams.value = JSON.parse(getParamByName("alldata", location.href));
            ccvAmount.value = getParamByName("amount", location.href);
            ccvCur.value = getParamByName("cur", location.href);
            let vvCustomerName = getParamByName("customername", location.href);
            let vvCustomerSurname = getParamByName("customersurname", location.href);
            let vvCustomerEmail = getParamByName("customeremail", location.href);
            console.log("PosNomuPayForm URL Params theParams VoucherNo: "+(theParams.value as any).VoucherNo);



            const vvXML = "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
                "<INPUT>\n" +
                "    <ServiceType>WDTicket</ServiceType>\n" +
                "    <OperationType>Sale3DSURLProxy</OperationType>\n" +
                "    <Token>\n" +
                "        <UserCode>"+(theParams.value as any).TerminalID+"</UserCode>\n" +
                "        <Pin>"+(theParams.value as any).MerchantID+"</Pin>\n" +
                "    </Token>\n" +
                "    <CustomerInfo>\n" +
                "        <CustomerName>"+vvCustomerName+"</CustomerName>\n" +
                "        <CustomerSurname>"+vvCustomerSurname+"</CustomerSurname>\n" +
                "        <CustomerEmail>"+vvCustomerEmail+"</CustomerEmail>\n" +
                "    </CustomerInfo>\n" +
                "    <Language>TR</Language>\n" +
                "    <Price>"+ccvAmount.value+"</Price>\n" +
                "    <MPAY></MPAY>\n" +
                "    <CurrencyCode>"+ccvCur.value+"</CurrencyCode>\n" +
                "    <ErrorURL>"+(theParams.value as any).ErrorUrl+"</ErrorURL>\n" +
                "    <SuccessURL>"+(theParams.value as any).SuccessUrl+"</SuccessURL>\n" +
                "    <ExtraParam></ExtraParam>\n" +
                "    <Description>"+(theParams.value as any).VoucherNo+"</Description>\n" +
                "    <PaymentContent>Bilgisayar</PaymentContent>\n" +
                "    <InstallmentOptions>"+(theParams.value as any).InstallmentCount+"</InstallmentOptions>\n" +
                "</INPUT>";

            console.log(""+vvXML);

            var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://www.nomupay.com.tr/SGate/Gate", true);
            xhr.setRequestHeader('Content-Type', 'application/xml');

            xhr.onreadystatechange = function() {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    console.log(xhr.responseText);

                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(xhr.responseText,"text/xml");

                    console.log("Result: "+xmlDoc.getElementsByTagName("Item")[0].childNodes[0]);

                    let vvResultMessage = "";
                    let vvRedirectUrl = "";

                    let arrResult = xmlDoc.getElementsByTagName("Item");
                    for (let i=0; i<arrResult.length; i++) {
                        let each = arrResult[i];
                        console.log("each "+each.getAttribute("Key"));
                        if (each.getAttribute("Key")==="ResultMessage") {
                            vvResultMessage = each.getAttribute("Value");
                        }
                        if (each.getAttribute("Key")==="RedirectUrl") {
                            vvRedirectUrl = each.getAttribute("Value");
                        }
                    }

                    if (vvResultMessage==="Basarili") {
                        window.location.href = vvRedirectUrl;
                    }

                }
            }

            xhr.send(vvXML);
             */

        });

        function getParamByName(name, url = window.location.href) {
            //name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        return {

        };
    }
});
